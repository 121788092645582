import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
  Avatar,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SchoolIcon from "@mui/icons-material/School";
import logo from "../assets/pcmlogo.png";

const Sidebar = ({ user, toggleModal }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
    alert("You have logged out successfully");
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isSidebarOpen ? 240 : 56, // Sidebar width when open or closed
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? 240 : 56, // Sidebar width when open or closed
          boxSizing: "border-box",
          backgroundColor: "#1e1e2f", // Change the background color
          color: "#fff", // Change the text color
        },
      }}
    >
      <Toolbar>
        <IconButton onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <CloseIcon sx={{ color: "#fff" }} />
          ) : (
            <MenuIcon sx={{ color: "#fff" }} />
          )}
        </IconButton>
        {isSidebarOpen && (
          <img
            src={logo}
            alt="Company Logo"
            style={{ width: "100px", marginLeft: "auto", marginRight: "auto" }}
          />
        )}
      </Toolbar>
      {isSidebarOpen && <Divider sx={{ backgroundColor: "#555770" }} />}
      {user && user.name && isSidebarOpen && (
        <Box sx={{ padding: "16px", textAlign: "center" }}>
          <Avatar sx={{ bgcolor: "goldenrod", margin: "0 auto" }}>
            {user.name.charAt(0)}
          </Avatar>
          <Typography
            variant="subtitle1"
            sx={{ color: "#fff", marginTop: "8px" }}
          >
            Welcome, {user.name}
          </Typography>
        </Box>
      )}
      <List>
        <ListItem
          button
          onClick={toggleModal}
          sx={{
            "&:hover": {
              backgroundColor: "#555770",
              color: "#FFD700", // Hover text color
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <ListItemIcon style={{ minWidth: "35px" }}>
            <AddIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          {isSidebarOpen && <ListItemText primary="Create Lead" />}
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/dashboard"
          sx={{
            "&:hover": {
              backgroundColor: "#555770",
              color: "#FFD700", // Hover text color
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <ListItemIcon style={{ minWidth: "35px" }}>
            <SearchIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          {isSidebarOpen && <ListItemText primary="Bucket Search" />}
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/colleges"
          sx={{
            "&:hover": {
              backgroundColor: "#555770",
              color: "#FFD700", // Hover text color
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <ListItemIcon style={{ minWidth: "35px" }}>
            <SchoolIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          {isSidebarOpen && <ListItemText primary="Colleges" />}
        </ListItem>

        {user.role === "manager" || user.role === "admin" ? (
          <>
            <ListItem
              button
              component={Link}
              to="/users"
              sx={{
                "&:hover": {
                  backgroundColor: "#555770",
                  color: "#FFD700", // Hover text color
                  transition: "all 0.3s ease-in-out",
                },
              }}
            >
              <ListItemIcon style={{ minWidth: "35px" }}>
                <GroupIcon sx={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="Users" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports"
              sx={{
                "&:hover": {
                  backgroundColor: "#555770",
                  color: "#FFD700", // Hover text color
                  transition: "all 0.3s ease-in-out",
                },
              }}
            >
              <ListItemIcon style={{ minWidth: "35px" }}>
                <AssessmentIcon sx={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="Reports" />}
            </ListItem>
          </>
        ) : null}

        <ListItem
          button
          component={Link}
          to="/profile"
          sx={{
            "&:hover": {
              backgroundColor: "#555770",
              color: "#FFD700", // Hover text color
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <ListItemIcon style={{ minWidth: "35px" }}>
            <AccountCircleIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          {isSidebarOpen && <ListItemText primary="Profile" />}
        </ListItem>

        {user ? (
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              "&:hover": {
                backgroundColor: "#555770",
                color: "#FFD700", // Hover text color
                transition: "all 0.3s ease-in-out",
              },
            }}
          >
            <ListItemIcon style={{ minWidth: "35px" }}>
              <LogoutIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Logout" />}
          </ListItem>
        ) : (
          <ListItem
            button
            component={Link}
            to="/login"
            sx={{
              "&:hover": {
                backgroundColor: "#555770",
                color: "#FFD700", // Hover text color
                transition: "all 0.3s ease-in-out",
              },
            }}
          >
            <ListItemIcon style={{ minWidth: "35px" }}>
              <LoginIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Login" />}
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
