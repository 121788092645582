// ForgotPassword.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/users/forgot-password`,
        { email }
      );
      localStorage.setItem("resetToken", data.token);
      localStorage.setItem("resetEmail", email);
      alert(data.message);
      navigate("/reset-password");
    } catch (error) {
      alert("Error sending reset token. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="form_container">
      <form className="form" onSubmit={handleSubmit}>
        <div className="flex-column">
          <label>Email</label>
        </div>
        <div className="inputForm">
          <input
            placeholder="Enter your Email"
            className="input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button className="button-submit" type="submit">
          Send Reset Token
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
