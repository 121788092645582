// ResetPassword.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("resetToken");
    const email = localStorage.getItem("resetEmail");
    if (!token || !email) {
      alert("No reset token found. Please request a new one.");
      navigate("/forgot-password");
      return;
    }
    const newOtp = Math.floor(1000 + Math.random() * 9000).toString();
    setOtp(newOtp); // Autofill OTP field
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("resetToken");
    const email = localStorage.getItem("resetEmail");
    if (!token || !email) {
      alert("No reset token found. Please request a new one.");
      navigate("/forgot-password");
      return;
    }
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/users/reset-password`,
        { email, token, password, otp }
      );
      alert(data.message);
      localStorage.removeItem("resetToken");
      localStorage.removeItem("resetEmail");
      navigate("/login");
    } catch (error) {
      alert("Error resetting password. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="form_container">
      <form className="form" onSubmit={handleSubmit}>
        <div className="flex-column">
          <label>New Password</label>
        </div>
        <div className="inputForm">
          <input
            placeholder="Enter your new password"
            className="input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex-column">
          <label>OTP</label>
        </div>
        <div className="inputForm">
          <input
            placeholder="Enter OTP"
            className="input"
            type="text"
            value={otp}
            readOnly
          />
        </div>
        <button className="button-submit" type="submit">
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
