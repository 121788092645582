import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Checkbox,
  TablePagination,
} from "@mui/material";
import { format } from "date-fns";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const LeadTable = ({
  leads,
  onLeadClick,
  userRole,
  userId,
  onDeleteLead,
  onDeleteMultipleLeads,
  onAssignLeads,
  onSelectedLeadsChange, // New prop for handling selected leads change
}) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    onSelectedLeadsChange(selectedLeads);
  }, [selectedLeads, onSelectedLeadsChange]);

  const handleViewClick = (lead) => {
    setSelectedLead(lead);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedLead(null);
  };

  const handleEmailClick = (email) => {
    const subject = "Inquiry from The Prince Career Maker";
    const body = `Hello,
  
  Thank you for reaching out to Prince Career Maker. We provide expert career guidance, counseling services, and educational resources to help you achieve your career goals. We also offer various training programs to enhance your skills and knowledge.
  
  Here are our contact details:
  📞 Phone: 8404814227
  📧 Email: support@princecareermaker.com
  📍 Address: Bodhichak, New JaganPura Rd, near Shri Ram Centennial School, Shahpur, Patna, Bihar 800027
  
  We look forward to assisting you in achieving your Career goals.
  
  Best Regards,
  Prince Career Maker`;

    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  const handleWhatsAppClick = (phone) => {
    const companyName = "Prince Career Maker";
    const contactNumber = "9973536921";
    const message = `अब पैसा नही बनेगा पढ़ाई मे बाधक क्यूँकि
  PRINCE CAREER MAKER है आपके साथ और लेकर आए है 
  Bihar Student Credit Card Scheme
  ✅ भारत के टॉप विश्वविद्यालय में पढ़ने का मौका
  ✅ NAAC "A"/NBA /NIRF/से Accredited & Ranked संस्थान में पढ़ने का मौका
  ✅ 10th & 12th पास STUDENT
   को आगे कि पढ़ाई के लिए बिहार सरकार स्टूडेंट क्रेडिट कार्ड के माध्यम से सम्पूर्ण फ़ीस पढ़ाई से रहने खाने तक का पैसा। साथ ही पढ़ाई पूर्ण होने पे नौकरी की सुविधा |
  ✅10th/12th उत्तीर्ण छात्र छात्राओं के लिए अधिक्तम आयु 25 years 
  ✅Graduation उत्तीर्ण छात्र छात्राओं के लिए अधिक्तम आयु 30 years  
  👉 B.Tech
  👉 Polytechnic
  👉 B pharmacy
  👉 BPT
  👉 B.sc Agriculture
  👉 Hotel Management
  👉 BBA / MBA
  👉 BCA / MCA
  And Various other Courses
  
  Call now📞 ${contactNumber}
  Prince Career Maker`;
  
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };
  

  const filteredLeads =
    userRole === "admin" || userRole === "manager"
      ? leads
      : leads.filter((lead) => lead.assignedTo === userId);

  const handleSelectAll = () => {
    if (selectedLeads.length === filteredLeads.length) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads(filteredLeads.map((lead) => lead._id));
    }
  };

  const handleSelectLead = (leadId) => {
    setSelectedLeads((prevSelectedLeads) => {
      const newSelectedLeads = prevSelectedLeads.includes(leadId)
        ? prevSelectedLeads.filter((id) => id !== leadId)
        : [...prevSelectedLeads, leadId];
      console.log('Selected Leads after select lead:', newSelectedLeads);
      return newSelectedLeads;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {(userRole === "admin" || userRole === "manager") && (
        <div style={{ marginBottom: "10px" }}>
          <Button
            onClick={() => onDeleteMultipleLeads(selectedLeads)}
            variant="contained"
            color="secondary"
            disabled={selectedLeads.length === 0}
            style={{ marginRight: "10px" }}
          >
            Delete Selected Leads
          </Button>
          <Button
            onClick={onAssignLeads}
            variant="contained"
            color="primary"
            disabled={selectedLeads.length === 0}
          >
            Assign Selected Leads
          </Button>
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto", width: "100%" }}
        style={{ backgroundColor: "#f7f7f7", borderRadius: "8px" }} // Set background color and rounded corners
      >
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "goldenrod" }}> {/* Header background color */}
              {(userRole === "admin" || userRole === "manager") && (
                <TableCell>
                  <Checkbox
                    checked={selectedLeads.length === filteredLeads.length}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedLeads.length > 0 &&
                      selectedLeads.length < filteredLeads.length
                    }
                    style={{ color: "black" }} // Checkbox color
                  />
                </TableCell>
              )}

              <TableCell style={{ color: "black" }}>S.No</TableCell>
              <TableCell style={{ color: "black" }}>Name</TableCell>
              <TableCell style={{ color: "black" }}>Email</TableCell>
              <TableCell style={{ color: "black" }}>Phone</TableCell>
              <TableCell style={{ color: "black" }}>Service</TableCell>
              <TableCell style={{ color: "black" }}>Price</TableCell>
              <TableCell style={{ color: "black" }}>Source</TableCell>
              <TableCell style={{ color: "black" }}>Status</TableCell>
              <TableCell style={{ color: "black" }}>Followup</TableCell>
              <TableCell style={{ color: "black" }}>Assigned To</TableCell>
              <TableCell style={{ color: "black" }}>Date</TableCell>
              <TableCell style={{ color: "black" }}>Actions</TableCell>
              {userRole === "admin" || userRole === "manager" ? (
                <TableCell style={{ color: "black" }}>Delete</TableCell>
              ) : null}
              <TableCell style={{ color: "white" }}>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeads
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((lead, index) => (
                <TableRow
                  key={lead._id}
                  onClick={() => onLeadClick(lead)}
                  style={{ cursor: "pointer", backgroundColor: index % 2 === 0 ? "#fff7e6" : "white" }} // Alternating row colors
                >
                  {(userRole === "admin" || userRole === "manager") && (
                    <TableCell>
                      <Checkbox
                        checked={selectedLeads.includes(lead._id)}
                        onChange={() => handleSelectLead(lead._id)}
                        onClick={(e) => e.stopPropagation()}
                        style={{ color: "black" }} // Checkbox color
                      />
                    </TableCell>
                  )}
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.phone}</TableCell>
                  <TableCell>{lead.service}</TableCell>
                  <TableCell>{lead.price}</TableCell>
                  <TableCell>{lead.source}</TableCell>
                  <TableCell>{lead.status}</TableCell>
                  <TableCell>
                    {lead.followup && lead.followup.length > 0
                      ? lead.followup[lead.followup.length - 1]
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {lead.assignedTo ? lead.assignedTo : "N/A"}
                  </TableCell>
                  <TableCell>
                    {lead.updatedAt
                      ? format(new Date(lead.updatedAt), "MM/dd/yyyy")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => onLeadClick(lead)}
                      variant="contained"
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  {userRole === "admin" || userRole === "manager" ? (
                    <TableCell>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteLead(lead._id);
                        }}
                        variant="contained"
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewClick(lead);
                      }}
                      variant="contained"
                      style={{ backgroundColor: "goldenrod", color: "black" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ color: "black" }}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            border: "1px solid #3f51b5",
            borderRadius: "10px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center" color="primary">
            Lead Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedLead && (
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" component="div" color="textSecondary">
                  Name:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.name}`}
                  </Typography>
                </Typography>
                <Typography variant="h6" component="div" color="textSecondary">
                  Email:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.email}`}
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" component="div" color="textSecondary">
                  Phone:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.phone}`}
                  </Typography>
                </Typography>
                <Typography variant="h6" component="div" color="textSecondary">
                  Service:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.service}`}
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" component="div" color="textSecondary">
                  Price:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.price}`}
                  </Typography>
                </Typography>
                <Typography variant="h6" component="div" color="textSecondary">
                  Source:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.source}`}
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" component="div" color="textSecondary">
                  Status:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${selectedLead.status}`}
                  </Typography>
                </Typography>
                <Typography variant="h6" component="div" color="textSecondary">
                  Assigned To:
                  <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                  >
                    {` ${
                      selectedLead.assignedTo ? selectedLead.assignedTo : "N/A"
                    }`}
                  </Typography>
                </Typography>
              </Box>
              <Typography variant="h6" gutterBottom color="textSecondary">
                Follow-ups:
              </Typography>
              <List>
                {selectedLead.followup.map((fu, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={fu} />
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <Button
                  onClick={() => handleEmailClick(selectedLead.email)}
                  variant="contained"
                  color="primary"
                  startIcon={<EmailIcon />}
                >
                  Email
                </Button>
                <Button
                  onClick={() => handleWhatsAppClick(selectedLead.phone)}
                  variant="contained"
                  color="success"
                  startIcon={<WhatsAppIcon />}
                >
                  WhatsApp
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#3f51b5",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#303f9f",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeadTable;
