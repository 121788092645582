import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Checkbox,
  MenuItem,
  Select,
  TablePagination,
  Dialog,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCollege from "./AddCollege";

const Colleges = () => {
  const [colleges, setColleges] = useState([]);
  const [isAddCollegeModalOpen, setIsAddCollegeModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [addingCollege, setAddingCollege] = useState(false);
  const [search, setSearch] = useState({
    name: "",
    city: "",
    state: "",
    creditCardAccepted: "",
    course: "",
    branch: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchColleges();
  }, [token]);

  const fetchColleges = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/colleges`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setColleges(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the colleges!", error);
      });
  };

  const Addcollegebutton = () => {
    setSelectedCollege(null); // Ensure no college is selected when adding a new one
    setAddingCollege(!addingCollege);
    setIsAddCollegeModalOpen(true);
  };

  const handleCloseAddCollegeModal = () => {
    setIsAddCollegeModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDeleteCollegeClick = (collegeId) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/colleges/${collegeId}`, {
        headers: { Authorization: token },
      })
      .then(() => {
        fetchColleges();
        alert("College deleted successfully");
      })
      .catch((error) => {
        console.error("There was an error deleting the college!", error);
        alert("There was an error deleting the college");
      });
  };

  const handleEditCollegeClick = (college) => {
    setSelectedCollege(college);
    setIsAddCollegeModalOpen(true);
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const filteredColleges = colleges.filter((college) => {
    const { name, city, state, creditCardAccepted, course, branch } = search;

    return (
      (!name || college.name.toLowerCase().includes(name.toLowerCase())) &&
      (!city || college.city.toLowerCase().includes(city.toLowerCase())) &&
      (!state || college.state.toLowerCase().includes(state.toLowerCase())) &&
      (!creditCardAccepted ||
        (creditCardAccepted === "Yes"
          ? college.creditCardAccepted
          : !college.creditCardAccepted)) &&
      (!course ||
        college.courses.some((c) =>
          c.name.toLowerCase().includes(course.toLowerCase())
        )) &&
      (!branch ||
        college.courses.some((c) =>
          c.branches.some((b) =>
            b.name.toLowerCase().includes(branch.toLowerCase())
          )
        ))
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#f4f6f8",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        user={JSON.parse(localStorage.getItem("user"))} // Get user from localStorage
      />
      <div style={{ flexGrow: 1, padding: "20px", width: "70%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Colleges</h1>
          <Button variant="contained" color="primary" onClick={Addcollegebutton}>
            Add College
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <TextField
            label="College Name"
            name="name"
            value={search.name}
            onChange={handleSearchChange}
            variant="outlined"
          />
          <TextField
            label="City"
            name="city"
            value={search.city}
            onChange={handleSearchChange}
            variant="outlined"
          />
          <TextField
            label="State"
            name="state"
            value={search.state}
            onChange={handleSearchChange}
            variant="outlined"
          />
          <Select
            name="creditCardAccepted"
            value={search.creditCardAccepted}
            onChange={handleSearchChange}
            displayEmpty
            variant="outlined"
          >
            <MenuItem value="">Credit Card Accepted</MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
          <TextField
            label="Course"
            name="course"
            value={search.course}
            onChange={handleSearchChange}
            variant="outlined"
          />
          <TextField
            label="Branch"
            name="branch"
            value={search.branch}
            onChange={handleSearchChange}
            variant="outlined"
          />
        </div>
        <TableContainer
          component={Paper}
          style={{ overflowX: "auto", width: "100%" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Credit Card Accepted</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Course Name</TableCell>
                <TableCell>Eligibility</TableCell>
                <TableCell>Course Fees</TableCell>
                <TableCell>Branches</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredColleges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((college) => (
                <TableRow key={college._id}>
                  <TableCell>{college.name}</TableCell>
                  <TableCell>{college.city}</TableCell>
                  <TableCell>{college.state}</TableCell>
                  <TableCell>{college.email}</TableCell>
                  <TableCell>
                    {college.creditCardAccepted ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    <a
                      href={college.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {college.website}
                    </a>
                  </TableCell>
                  <TableCell>
                    {college.courses.map((course, courseIndex) => (
                      <div key={courseIndex}>{course.name}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {college.courses.map((course, courseIndex) => (
                      <div key={courseIndex}>{course.eligibility}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {college.courses.map((course, courseIndex) => (
                      <div key={courseIndex}>₹{course.fees}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {college.courses.map((course, courseIndex) => (
                      <ul key={courseIndex}>
                        {course.branches.map((branch, branchIndex) => (
                          <li key={branchIndex}>{branch.name}</li>
                        ))}
                      </ul>
                    ))}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditCollegeClick(college)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDeleteCollegeClick(college._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredColleges.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          open={isAddCollegeModalOpen}
          onClose={handleCloseAddCollegeModal}
          maxWidth="md"
          fullWidth
        >
          <AddCollege onClose={handleCloseAddCollegeModal} open={isAddCollegeModalOpen} selectedCollege={selectedCollege} />
        </Dialog>
      </div>
    </div>
  );
};

export default Colleges;
