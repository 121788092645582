import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import LeadTable from "./Leadlist";
import axios from "axios";
import CreateLeadModal from "./CreateLeadModal";
import Sidebar from "./Sidebar";
import logo from "../assets/pcmlogo.png";

const Dashboard = () => {
  const [leads, setLeads] = useState([]);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [price, setPrice] = useState("");
  const [source, setSource] = useState("");
  const [status, setStatus] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const [editLead, setEditLead] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]); // Use state only in the parent
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [followup, setFollowup] = useState("");

  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchService, setSearchService] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setToken(storedToken);
    setUser(storedUser);
    fetchLeads(storedToken);
    if (storedUser.role === "manager" || storedUser.role === "admin") {
      fetchUsers(storedToken);
    }
  }, []);

  const fetchLeads = async (token) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/leads`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setLeads(data);
  };

  const fetchUsers = async (token) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/users/getonlyuser`,
      {
        headers: { Authorization: token },
      }
    );
    setUsers(data.filter((u) => u.role === "user"));
  };

const handleDeleteLead = async (leadId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/leads/delete/${leadId}`,
        {
          headers: { Authorization: token },
        }
      );
      setLeads(leads.filter((lead) => lead._id !== leadId));
      alert("Lead delete successfully");
    } catch (error) {
      console.error("Failed to delete lead:", error);
    }
  };

  const handleDeleteMultipleLeads = async (leadIds) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/leads/deletemultiple`,
        {
          leadIds,
        },
        {
          headers: { Authorization: token },
        }
      );
      setLeads(leads.filter((lead) => !leadIds.includes(lead._id)));
      alert("Leads deleted successfully");
    } catch (error) {
      console.error("Failed to delete leads:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/leads/add`,
      {
        name,
        email,
        phone,
        service,
        price,
        source,
        status,
        followup,
        createdBy: user._id,
        assignedTo: user.role === "manager" ? assignedTo : null,
      },
      {
        headers: { Authorization: token },
      }
    );
    toggleModal();
    fetchLeads(token);
    setName("");
    setEmail("");
    setPhone("");
    setService("");
    setPrice("");
    setSource("");
    setStatus("");
    setAssignedTo("");
  };

  const handleUpdate = async (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/leads/update/${editLead._id}`,
      {
        name: editLead.name,
        email: editLead.email,
        phone: editLead.phone,
        service: editLead.service,
        price: editLead.price,
        source: editLead.source,
        status: status,
        followup: editLead.followup,
        assignedTo: user.role === "manager" ? editLead.assignedTo : null,
      },
      {
        headers: { Authorization: token },
      }
    );
    toggleEditModal();
    alert("you have updated the Lead successfully");
    fetchLeads(token);
  };

  const handleAssignLeads = async (e) => {
    e.preventDefault();
    console.log("Selected Leads before API call:", selectedLeads);
    console.log("Assigned To:", assignedTo);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/leads/assignmultiple`,
        {
          leadIds: selectedLeads,
          assignedTo,
        },
        {
          headers: { Authorization: token },
        }
      );
      setLeads(
        leads.map((lead) =>
          selectedLeads.includes(lead._id) ? { ...lead, assignedTo } : lead
        )
      );
      setSelectedLeads([]);
      toggleAssignModal();
      alert("Leads assigned successfully");
    } catch (error) {
      console.error("Failed to assign leads:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleAssignModal = () => {
    setIsAssignModalOpen(!isAssignModalOpen);
  };

  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "searchName":
        setSearchName(value);
        break;
      case "searchEmail":
        setSearchEmail(value);
        break;
      case "searchPhone":
        setSearchPhone(value);
        break;
      case "searchStatus":
        setSearchStatus(value);
        break;
      case "searchService":
        setSearchService(value);
        break;
      default:
        break;
    }
  };

  const filteredLeads = leads.filter((lead) => {
    return (
      (searchName
        ? lead.name.toLowerCase().includes(searchName.toLowerCase())
        : true) &&
      (searchEmail
        ? lead.email.toLowerCase().includes(searchEmail.toLowerCase())
        : true) &&
      (searchPhone ? lead.phone.includes(searchPhone) : true) &&
      (searchStatus ? lead.status === searchStatus : true) &&
      (searchService
        ? lead.service.toLowerCase().includes(searchService.toLowerCase())
        : true)
    );
  });

  const handleLeadClick = (lead) => {
    if (lead.followups && lead.followups.length > 0) {
      // Sort followups by date, assuming each followup has a `date` property
      const sortedFollowups = lead.followups.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      const mostRecentFollowup = sortedFollowups[0].text;

      const leadToEdit = {
        ...lead,
        followup: mostRecentFollowup,
      };

      setEditLead(leadToEdit);
    } else {
      setEditLead({ ...lead, followup: "" });
    }

    setIsEditModalOpen(true);
  };

  return (
    <div style={{ display: "flex", backgroundColor: "#f4f6f8" }}>
      {/* Sidebar */}

      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        user={user}
        toggleModal={toggleModal}
      />
      {/* Main Content Area */}
      <div style={{ flexGrow: 1, padding: "20px", width: "70%", margin: "0 auto" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", flexWrap: "wrap" }}>
        <img
          src={logo}
          alt="Company Logo"
          style={{ width: "150px", marginBottom: "20px" }}
        />
        <h1 style={{
          fontSize: "3rem",
          color: "#007BFF", // Primary color for the "Dashboard" text
          textAlign: "center",
          flexGrow: 1,
          margin: 0 // Ensure no margin affects the layout
        }}>
        <span style={{ color: "goldenrod" }}>DASH</span><span style={{ color: "black" }}>BOARD</span>
        </h1>
      </div>

      {/* Search Fields */}
      <div style={{ marginBottom: "20px", width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <TextField
          name="searchName"
          value={searchName}
          onChange={handleSearchInputChange}
          label="Search by Name"
          style={{ flex: "1 1 200px" }}
        />
        <TextField
          name="searchEmail"
          value={searchEmail}
          onChange={handleSearchInputChange}
          label="Search by Email"
          style={{ flex: "1 1 200px" }}
        />
        <TextField
          name="searchPhone"
          value={searchPhone}
          onChange={handleSearchInputChange}
          label="Search by Phone"
          style={{ flex: "1 1 200px" }}
        />
        <TextField
          name="searchService"
          value={searchService}
          onChange={handleSearchInputChange}
          label="Search by Service"
          style={{ flex: "1 1 200px" }}
        />
        <Select
          name="searchStatus"
          value={searchStatus}
          onChange={handleSearchInputChange}
          displayEmpty
          style={{ flex: "1 1 200px", minWidth: "150px" }}
        >
          <MenuItem value="">Search by Status</MenuItem>
          <MenuItem value="new">New</MenuItem>
          <MenuItem value="contacted">Contacted</MenuItem>
          <MenuItem value="qualified">Qualified</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="lost">Lost</MenuItem>
          <MenuItem value="won">Won</MenuItem>
        </Select>
      </div>

      {/* Leads List */}
      <div style={{ width: "100%" }}>
        <LeadTable
          leads={filteredLeads}
          onLeadClick={handleLeadClick}
          userRole={user?.role}
          userId={user?.email}
          onDeleteLead={handleDeleteLead}
          onDeleteMultipleLeads={handleDeleteMultipleLeads}
          onAssignLeads={toggleAssignModal}
          onSelectedLeadsChange={setSelectedLeads} // Pass callback to handle selected leads
        />
      </div>
    </div>
      {/* Create Lead Modal */}
      <CreateLeadModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        handleSubmit={handleSubmit}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        service={service}
        setService={setService}
        price={price}
        setPrice={setPrice}
        source={source}
        setSource={setSource}
        status={status}
        setStatus={setStatus}
        user={user}
        assignedTo={assignedTo}
        setAssignedTo={setAssignedTo}
        followup={followup}
        setFollowup={setFollowup}
        users={users}
      />
      {/* Edit Lead Modal */}
      <Modal open={isEditModalOpen} onClose={toggleEditModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <h2>Edit Lead</h2>
          {editLead && (
            <form onSubmit={handleUpdate}>
              <TextField
                type="text"
                value={editLead.name}
                onChange={(e) =>
                  setEditLead({ ...editLead, name: e.target.value })
                }
                label="Lead Name"
                required
                style={{ marginBottom: "10px" }}
              />
              <TextField
                type="email"
                value={editLead.email}
                onChange={(e) =>
                  setEditLead({ ...editLead, email: e.target.value })
                }
                label="Lead Email"
                required
                style={{ marginBottom: "10px" }}
              />
              <TextField
                type="text"
                value={editLead.phone}
                onChange={(e) =>
                  setEditLead({ ...editLead, phone: e.target.value })
                }
                label="Phone"
                required
                style={{ marginBottom: "10px" }}
              />
              <TextField
                type="text"
                value={editLead.service}
                onChange={(e) =>
                  setEditLead({ ...editLead, service: e.target.value })
                }
                label="Service"
                required
                style={{ marginBottom: "10px" }}
              />
              <TextField
                type="number"
                value={editLead.price}
                onChange={(e) =>
                  setEditLead({ ...editLead, price: e.target.value })
                }
                label="Price"
                style={{ marginBottom: "10px" }}
              />
              <TextField
                type="text"
                value={editLead.source}
                onChange={(e) =>
                  setEditLead({ ...editLead, source: e.target.value })
                }
                label="Source"
                style={{ marginBottom: "10px" }}
              />
              <TextField
                type="text"
                value={editLead.followup}
                onChange={(e) =>
                  setEditLead({ ...editLead, followup: e.target.value })
                }
                label="Follow Up"
                style={{ marginBottom: "10px" }}
              />
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                displayEmpty
                fullWidth
                required
                sx={{ marginBottom: "10px" }}
              >
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="new">New</MenuItem>
                <MenuItem value="contacted">Contacted</MenuItem>
                <MenuItem value="qualified">Qualified</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="lost">Lost</MenuItem>
                <MenuItem value="won">Won</MenuItem>
              </Select>
              {user.role === "manager" && (
                <Select
                  value={editLead.assignedTo}
                  onChange={(e) =>
                    setEditLead({ ...editLead, assignedTo: e.target.value })
                  }
                  displayEmpty
                  style={{ marginBottom: "10px" }}
                >
                  <MenuItem value="" disabled>
                    Select User
                  </MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user._id} value={user.email}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <Button type="submit" variant="contained">
                Update Lead
              </Button>
            </form>
          )}
        </div>
      </Modal>
      {/* Assign Leads Modal */}
      <Modal open={isAssignModalOpen} onClose={toggleAssignModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <h2>Assign Leads</h2>
          <form onSubmit={handleAssignLeads}>
            <Select
              value={assignedTo}
              onChange={(e) => setAssignedTo(e.target.value)}
              displayEmpty
              fullWidth
              required
              sx={{ marginBottom: "10px" }}
            >
              <MenuItem value="" disabled>
                Select User
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user._id} value={user.email}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
            <Button type="submit" variant="contained">
              Assign
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
