import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
} from '@mui/material';
import CollegeBulkUpload from './CollegeBulkUpload';

const AddCollege = ({ onClose, open, selectedCollege }) => {
  const [college, setCollege] = useState({
    name: '',
    city: '',
    state: '',
    creditCardAccepted: false,
    website: '',
    email: '',
    courses: [{ name: '', eligibility: '', fees: 0, branches: [{ name: '' }] }]
  });

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (selectedCollege) {
      setCollege(selectedCollege);
    }
  }, [selectedCollege]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCollege({
      ...college,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleCourseChange = (index, e) => {
    const { name, value } = e.target;
    const courses = [...college.courses];
    courses[index][name] = value;
    setCollege({ ...college, courses });
  };

  const handleBranchChange = (courseIndex, branchIndex, e) => {
    const { value } = e.target;
    const courses = [...college.courses];
    courses[courseIndex].branches[branchIndex].name = value;
    setCollege({ ...college, courses });
  };

  const addCourse = () => {
    setCollege({
      ...college,
      courses: [...college.courses, { name: '', eligibility: '', fees: 0, branches: [{ name: '' }] }]
    });
  };

  const addBranch = (courseIndex) => {
    const courses = [...college.courses];
    courses[courseIndex].branches.push({ name: '' });
    setCollege({ ...college, courses });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const request = selectedCollege
      ? axios.put(`${process.env.REACT_APP_BASE_URL}/api/colleges/${selectedCollege._id}`, college, { headers: { Authorization: token } })
      : axios.post(`${process.env.REACT_APP_BASE_URL}/api/colleges`, college, { headers: { Authorization: token } });

    request
      .then(response => {
        console.log(response.data);
        alert(selectedCollege ? 'College updated successfully!' : 'College added successfully!');
        onClose();
      })
      .catch(error => {
        console.error('There was an error!', error);
        alert('Error: ' + error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{selectedCollege ? 'Edit College' : 'Add College'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name *"
            name="name"
            value={college.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="City *"
            name="city"
            value={college.city}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="State *"
            name="state"
            value={college.state}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="creditCardAccepted"
                checked={college.creditCardAccepted}
                onChange={handleChange}
              />
            }
            label="Credit Card Accepted"
          />
          <TextField
            label="Website *"
            name="website"
            value={college.website}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email *"
            name="email"
            value={college.email}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" component="h2" gutterBottom>
            Courses
          </Typography>
          {college.courses.map((course, courseIndex) => (
            <Paper key={courseIndex} variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
              <TextField
                label="Course Name *"
                name="name"
                value={course.name}
                onChange={(e) => handleCourseChange(courseIndex, e)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Eligibility *"
                name="eligibility"
                value={course.eligibility}
                onChange={(e) => handleCourseChange(courseIndex, e)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Semester Fees *"
                name="fees"
                value={course.fees}
                onChange={(e) => handleCourseChange(courseIndex, e)}
                required
                fullWidth
                margin="normal"
                type="number"
              />
              <Typography variant="subtitle1" gutterBottom>
                Branches
              </Typography>
              {course.branches.map((branch, branchIndex) => (
                <TextField
                  key={branchIndex}
                  label="Branch Name"
                  value={branch.name}
                  onChange={(e) => handleBranchChange(courseIndex, branchIndex, e)}
                  fullWidth
                  margin="normal"
                />
              ))}
              {/* <Button
                variant="contained"
                color="secondary"
                onClick={() => addBranch(courseIndex)}
                style={{ marginTop: '10px' }}
              >
                Add Branch
              </Button> */}
            </Paper>
          ))}
          {/* <Button
            variant="contained"
            color="secondary"
            onClick={addCourse}
            style={{ marginTop: '20px' }}
          >
            Add Course
          </Button> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '20px', marginLeft: '10px' }}
          >
            Submit
          </Button>
        </form>
        <CollegeBulkUpload user={JSON.parse(localStorage.getItem('user'))} onClose={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCollege;
