import React from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import axios from 'axios';

const CollegeBulkUpload = ({ user, onClose }) => {
  const handleDownloadTemplate = () => {
    const csvData = [
      [
        'name *',
        'city *',
        'state *',
        'creditCardAccepted *',
        'website *',
        'email *',
        'courseName *',
        'eligibility *',
        'semesterFees *',
        'branchName',
      ],
    ];
    const blob = new Blob([Papa.unparse(csvData)], {
      type: 'text/csv;charset=utf-8;',
    });
    saveAs(blob, 'college_template.csv');
  };

  const handleCSVUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        transformHeader: (header) => header.trim().replace(' *', ''),
        complete: async (results) => {
          console.log('Parsed Results:', results.data);  // Log the parsed data

          const validColleges = results.data.filter((college) => 
            college.name &&
            college.city &&
            college.state &&
            college.creditCardAccepted !== undefined &&
            college.website &&
            college.email &&
            college.courseName &&
            college.eligibility &&
            college.semesterFees
          );

          console.log('Valid Colleges:', validColleges);  // Log valid colleges

          if (validColleges.length === 0) {
            alert('No valid data found in the CSV file. Please check your file and try again.');
            return;
          }

          const formattedColleges = validColleges.reduce((acc, curr) => {
            let college = acc.find((c) => c.name === curr.name);
            if (!college) {
              college = {
                name: curr.name,
                city: curr.city,
                state: curr.state,
                creditCardAccepted: curr.creditCardAccepted.toLowerCase() === 'true',
                website: curr.website,
                email: curr.email,
                courses: [],
              };
              acc.push(college);
            }
            let course = college.courses.find((c) => c.name === curr.courseName);
            if (!course) {
              course = {
                name: curr.courseName,
                eligibility: curr.eligibility,
                fees: parseFloat(curr.semesterFees),
                branches: [],
              };
              college.courses.push(course);
            }
            if (curr.branchName) {
              course.branches.push({ name: curr.branchName });
            }
            return acc;
          }, []);

          console.log('Formatted Colleges:', formattedColleges);  // Log formatted colleges

          const token = localStorage.getItem('token');
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/colleges/bulk`,
              { colleges: formattedColleges },
              {
                headers: { Authorization: token }
              }
            );
            if (response.status === 201) {
              alert('Colleges uploaded successfully!');
              onClose();
              window.location.reload();
            }
          } catch (error) {
            alert('There was an error uploading the colleges: ' + error?.response?.data?.error);
            console.error('There was an error uploading the colleges!', error);
          }
        },
        error: (error) => {
          console.error('Parsing error:', error);
          alert('Error parsing the CSV file. Please check the file and try again.');
        }
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDownloadTemplate}
        fullWidth
        sx={{ marginBottom: '10px' }}
      >
        Download CSV Template
      </Button>
      <Button
        variant="contained"
        component="label"
        fullWidth
        sx={{
          marginBottom: '10px',
          backgroundColor: '#4caf50',
          color: '#fff',
        }}
      >
        Upload CSV
        <input
          type="file"
          accept=".csv"
          onChange={handleCSVUpload}
          hidden
        />
      </Button>
    </>
  );
};

export default CollegeBulkUpload;
