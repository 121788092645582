import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const UserTable = ({ users, onUserClick, currentUserRole, onDeleteUser, onViewUser }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user._id} style={{ cursor: 'pointer' }}>
              <TableCell onClick={() => onUserClick(user)}>{user.name}</TableCell>
              <TableCell onClick={() => onUserClick(user)}>{user.email}</TableCell>
              <TableCell onClick={() => onUserClick(user)}>{user.role}</TableCell>
              <TableCell>
                <Button onClick={(e) => { e.stopPropagation(); onViewUser(user); }}>View</Button>
                {(currentUserRole === 'admin' || currentUserRole === 'manager') && (
                  <Button color="error" onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event
                    onDeleteUser(user._id);
                  }}>Delete</Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
